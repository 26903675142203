<template>
    <v-progress-linear
        v-if="loadings.adminUser"
        color="purple darken-2"
        indeterminate
    />
    <div v-else-if="adminUser" class="mt-5">
        <p>В этом интерфейсе Вы можете восстановить доступ к учетной записи Системного Администратора продукта ОКО ВПР, в случае его утраты.</p>
        <p>Логин Системного Администратора продукта ОКО ВПР – <strong>{{ adminUser.login }}</strong>.</p>
        <p>Для сброса пароля нажмите на кнопку «Заменить пароль», и укажите новый пароль для Системного Администратора продукта ОКО ВПР.</p>

        <v-btn color="purple darken-2" dark @click="dialogs.updateUserPassword = true">Заменить пароль</v-btn>

        <v-dialog v-model="dialogs.updateUserPassword" max-width="600px">
            <v-card class="pa-4">
                <v-card-title class="headline px-0">Обновить пароль</v-card-title>
                <update-user-password-form
                    :key="dialogs.updateUserPassword"
                    :model="adminUser"
                    :disabled="!isCanEditUpdateAdminPasswordSection"
                    @success="onUpdatePasswordSuccess"
                />
            </v-card>
        </v-dialog>
    </div>
    <p v-else>Не удалось получить данные с сервера</p>
</template>

<script>
import UpdateUserPasswordForm from '@/components/forms/UpdateUserPasswordForm'
import isUserCanMixin from '@/mixins/isUserCanMixin'

export default {
    components: { UpdateUserPasswordForm },
    mixins: [ isUserCanMixin ],
    created () {
        this.fetchAdminUser()
    },
    data () {
        return {
            adminUser: null,
            loadings: {
                adminUser: false
            },
            dialogs: {
                updateUserPassword: false
            }
        }
    },
    methods: {
        async fetchAdminUser () {
            try {
                this.loadings.adminUser = true
                const { success, error, data } = await this.$store.dispatch('user/one', {
                    id: this.$store.state.user.profile.tree,
                    fields: ['name', 'role', 'login'].join(',')
                })
                
                if (!success)
                    throw new Error(error)
                
                this.adminUser = data
            } catch (e) {
                console.error(e)
            } finally {
                this.loadings.adminUser = false
            }
        },
        onUpdatePasswordSuccess () {
            this.$root.$emit('snack-bar', { text: 'Пароль успешно обновлён' })
            this.dialogs.updateUserPassword = false
        }
    }
}
</script>