<template>
    <v-form>
        <v-text-field 
            v-model="$v.form.new_password.$model"
            :error-messages="getErrors('form.new_password')"
            autocomplete="new-password"
            label="Пароль" 
            name="new_password" 
            :disabled="disabled"
            type="password"
            placeholder=" " 
            color="purple darken-2"
        ></v-text-field>

        <v-text-field 
            v-model="$v.form.confirm_password.$model"
            :error-messages="getErrors('form.confirm_password', {
                compare: 'Пароль не совпадает с подтверждением'
            })"
            :disabled="disabled"
            label="Подтверждение пароля"
            name="confirm_password" 
            type="password" 
            placeholder=" " 
            color="purple darken-2"
        ></v-text-field>

        <v-divider class="my-4" />

        <v-btn
            :dark="!disabled"
            color="purple darken-2" 
            small
            :disabled="disabled"
            :loading="waiting.save.update" 
            @click.prevent.stop="dialogs.update = true"
        >
            Обновить
        </v-btn>

        <confirm-action-with-pass-dialog
            v-model="dialogs.update"
            :action="save"
        />

        <v-alert
          v-if="!_.isNil(serverErrors)"
          dense
          type="error"
          class="mt-4"
        >
            <span v-for="(errors, ei1) in serverErrors" :key="ei1" class="d-flex flex-column">
                <span v-for="(error, ei2) in errors" :key="ei2" class="d-flex flex-column">{{ error }}</span>
            </span>
        </v-alert>
    </v-form>
</template>
<script>
import { required, minLength } from 'vuelidate/lib/validators'
import UserForm from './UserForm.vue'
import ConfirmActionWithPassDialog from '@/components/template/ConfirmActionWithPassDialog.vue'

export default {
    mixins: [ _.omit(UserForm, 'created') ],
    components: { ConfirmActionWithPassDialog },
    props: {
        model: { type: Object, required: true },
        disabled: { type: Boolean, default: false }
    },
    data () {
        return {
            form: {
                new_password: '',
                confirm_password: ''
            },
            dialogs: {
                update: false
            }
        }
    },
    validations() {
        const PASSWORD_MIN_LENGTH = 6;
        return {
            form: {
                new_password: {
                    required,
                    minLength: minLength(PASSWORD_MIN_LENGTH)
                },
                confirm_password: {
                    required,
                    compare: function(value) {
                        return this.form.new_password.length > 0 ? this.form.new_password === value : true;  
                    },
                    minLength: minLength(PASSWORD_MIN_LENGTH)
                }
            }
        }
    },
    methods: {
        prepareForm(form) {
            return form;
        }
    }
}
</script>